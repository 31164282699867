import React, { useEffect, useMemo } from "react";
import styles from "./DonorNeeds.module.scss";
import { SHORT_BLOOD_WAITING, WAITING_BLOOD_CLASES } from "../../entities";
import UserAvatar from "../ui/userAvatar";
import { useRecipientStore } from "../recipient/ui/store";
import { TRecipientData } from "../../stores/RecipientStore";
import CustomCarousel from "../ui/customCarousel";
import moment from "moment";
import redirectTo from "../../utils/redirectTo";
import BlockContainer from "../mainPageBlocks/blockContainer";
import scss from "../mainPageBlocks/bannersBlock/bannerBlock.module.scss";
import Spinner from "../spinner";

const LOCAL_BLOOD_CLASSES = {
  ...WAITING_BLOOD_CLASES,
  leukocytes: {
    title: "Гранулоциты",
    icon: "/static/images/icons/leukocytes.svg",
    not_approved_icon: "/static/images/icons/not_approved_leukocytes.svg",
  },
  any: {
    title: "Любой компонент",
    icon: "/static/images/icons/allComponents.svg",
  },
};

const LOCAL_SHORT_BLOOD_WAITING = {
  ...SHORT_BLOOD_WAITING,
  any: "Любая группа крови",
};

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1920 },
    items: 5,
    slidesToSlide: 5,
  },
  desktop: {
    breakpoint: { max: 1919, min: 1440 },
    items: 4,
    slidesToSlide: 4,
  },
  laptop: {
    breakpoint: { max: 1439, min: 1200 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1199, min: 768 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 360 },
    items: 1,
    slidesToSlide: 1,
  },
};

export const DonorNeedsBlock: React.FC<{
  titleClass?: any;
  main_page?: boolean;
  id?: number;
}> = ({ titleClass = "", main_page = false, id }) => {
  const {
    bloodStationRecipientNeeds,
    getRecipientDonorNeedsMain,
    clearRecipientDonorNeeds,
    mainLoading,
  } = useRecipientStore();

  useEffect(() => {
    getRecipientDonorNeedsMain(main_page, id);
    return () => {
      clearRecipientDonorNeeds();
    };
  }, [id, main_page]);

  if (mainLoading && !bloodStationRecipientNeeds?.length)
    return (
      <div className={`${scss.bonusBlock} container`}>
        <Spinner toggled={true} background={true} />
      </div>
    );

  if (!bloodStationRecipientNeeds?.length) return null;

  return (
    <BlockContainer
      linkUrl={"/helpme/"}
      isLink={true}
      linkText={"Найти донора"}
      isGrid
      isBonus
      title={"Срочно нужны доноры"}
      titleClass={titleClass}
    >
      <CustomCarousel
        responsive={responsive}
        partialVisible={false}
        draggable={true}
        swipeable={true}
        arrows={true}
        infinite={true}
        dots={true}
        removeArrowOnDeviceType={["mobile"]}
      >
        {bloodStationRecipientNeeds.map((item) => (
          <div
            className={styles.Card_Need}
            key={`${item.object_id}_${item.id}`}
          >
            <DonorNeedCard data={item} main_page={main_page} />
          </div>
        ))}
      </CustomCarousel>
    </BlockContainer>
  );
};

const DonorNeeds: React.FC<{ main_page?: boolean }> = ({
  main_page = false,
}) => {
  const { bloodStationRecipientNeeds } = useRecipientStore();

  if (!bloodStationRecipientNeeds?.length) return null;

  return (
    <div className={styles.DonorNeeds}>
      <div className={styles.DonorNeeds_Title}>
        <span>Срочно нужны доноры</span>
      </div>
      <div className={styles.DonorNeeds_Content}>
        <CustomCarousel
          responsive={responsive}
          partialVisible={false}
          draggable={true}
          swipeable={true}
          arrows={true}
          infinite={true}
          dots={true}
          removeArrowOnDeviceType={["mobile"]}
        >
          {bloodStationRecipientNeeds.map((item) => (
            <DonorNeedCard
              data={item}
              key={`${item.object_id}_${item.id}`}
              main_page={main_page}
            />
          ))}
        </CustomCarousel>
      </div>
    </div>
  );
};

export const DonorNeedCard: React.FC<{
  data: TRecipientData;
  main_page?: boolean;
  past?: boolean;
}> = ({ data, main_page = false, past = false }) => {
  const { getRecipientBloodNeedsByRecipientId } = useRecipientStore();

  const needs = useMemo(() => {
    return getRecipientBloodNeedsByRecipientId(data);
  }, [data]);

  const joined_users = data.joined_users.filter((_, idx) => idx < 5);

  const onClick = () => {
    redirectTo(`/recipient/${data.id}/`);
  };

  const name = useMemo(() => {
    const full = [data.last_name, data.first_name].filter(Boolean).join(" ");
    if (full.length >= 18) {
      return `${full.slice(0, 18)}...`;
    } else {
      return full;
    }
  }, [data.first_name, data.last_date]);

  return (
    <div className={styles.DonorNeedCard} onClick={onClick}>
      <div className={styles.DonorNeedCard_Container}>
        <div className={styles.DonorNeedCard_Container_Header}>
          <div className={styles.DonorNeedCard_Container_Header_Top}>
            <div className={styles.DonorNeedCard_Container_Header_Top_Left}>
              <img
                src={LOCAL_BLOOD_CLASSES[data.blood_class].icon}
                alt={LOCAL_BLOOD_CLASSES[data.blood_class].title}
              />
              <span>{LOCAL_BLOOD_CLASSES[data.blood_class].title}</span>
            </div>
            <div className={styles.DonorNeedCard_Container_Header_Top_Right}>
              <span>{moment(data.last_date).format("[До] D MMMM")}</span>
            </div>
          </div>
          <div className={styles.DonorNeedCard_Container_Header_Mid}>
            {needs?.length < 8 ? (
              needs?.map((item) => (
                <DonorNeedCardNeed
                  item={item}
                  key={`${item.name}_${item.value}`}
                />
              ))
            ) : (
              <div className={styles.DonorNeedCardNeed}>
                <span>{LOCAL_SHORT_BLOOD_WAITING.any}</span>
              </div>
            )}
          </div>
          <div className={styles.DonorNeedCard_Container_Header_Bot}>
            <span>{past ? "Поиск донора" : name}</span>
          </div>
        </div>
        <div className={styles.DonorNeedCard_Container_Body}>
          {main_page && (
            <div className={styles.DonorNeedCard_Container_Body_Address}>
              <div
                className={styles.DonorNeedCard_Container_Body_Address_Image}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 12.75C13.6569 12.75 15 11.4069 15 9.75C15 8.09315 13.6569 6.75 12 6.75C10.3431 6.75 9 8.09315 9 9.75C9 11.4069 10.3431 12.75 12 12.75Z"
                    stroke="#4E5355"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.5 9.75C19.5 16.5 12 21.75 12 21.75C12 21.75 4.5 16.5 4.5 9.75C4.5 7.76088 5.29018 5.85322 6.6967 4.4467C8.10322 3.04018 10.0109 2.25 12 2.25C13.9891 2.25 15.8968 3.04018 17.3033 4.4467C18.7098 5.85322 19.5 7.76088 19.5 9.75V9.75Z"
                    stroke="#4E5355"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className={styles.DonorNeedCard_Container_Body_Address_Text}>
                <span>
                  {[
                    data?.blood_station?.city?.title,
                    data?.blood_station?.address,
                  ]
                    .filter(Boolean)
                    .join(", ")}
                </span>
              </div>
            </div>
          )}
          <div className={styles.DonorNeedCard_Container_Body_Users}>
            <div className={styles.DonorNeedCard_Container_Body_Users_Count}>
              <span>
                {data.total_joined_count}/{data.required_donors_count} уже идут
                {!data.total_joined_count && ", станьте первым!"}
              </span>
            </div>
            <div className={styles.DonorNeedCard_Container_Body_Users_Images}>
              {joined_users.map((user) => (
                <UserAvatar user={user.user} scale={40} key={user.user.id} />
              ))}
            </div>
          </div>
          {data.author && (
            <div className={styles.DonorNeedCard_Container_Body_Author}>
              <div
                className={styles.DonorNeedCard_Container_Body_Author_Header}
              >
                <span>Ищет</span>
              </div>
              <div className={styles.DonorNeedCard_Container_Body_Author_Body}>
                {data?.author && (
                  <UserAvatar
                    field={"image"}
                    scale={48}
                    user={data.author}
                    style={{
                      width: "auto",
                      marginRight: 16,
                    }}
                  />
                )}
                <div
                  className={
                    styles.DonorNeedCard_Container_Body_Author_Body_Text
                  }
                >
                  <span>{data.author.name}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const DonorNeedCardNeed = ({ item }) => {
  return (
    <div className={styles.DonorNeedCardNeed}>
      <span>{LOCAL_SHORT_BLOOD_WAITING[item.name]}</span>
    </div>
  );
};

export default DonorNeeds;
