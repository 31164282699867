import { useStore } from "../../../hooks/mobx";
import { useObserver } from "mobx-react";
import RecipientStore from "../../../stores/RecipientStore";
import AuthStore from "../../../stores/AuthStore";
import UiStore from "../../../stores/UiStore";
import HelpMeStore from "../../../stores/HelpMeStore";

export const useRecipientStore = () => {
  const {
    recipientStore,
    authStore,
    uiStore,
    helpMeStore,
  }: {
    recipientStore: RecipientStore;
    authStore: AuthStore;
    uiStore: UiStore;
    helpMeStore: HelpMeStore;
  } = useStore(["recipientStore", "authStore", "uiStore", "helpMeStore"]);
  return useObserver(() => ({
    getRecipientBloodNeedsByRecipientId:
      recipientStore.getRecipientBloodNeedsByRecipientId,
    bloodStationRecipientNeeds: recipientStore.bloodStationRecipientNeeds,
    getRecipientDonorNeedsMain: recipientStore.getRecipientDonorNeedsMain,
    clearRecipientDonorNeeds: recipientStore.clearRecipientDonorNeeds,
    recipientChangeTimeSlot: recipientStore.recipientChangeTimeSlot,
    recipientBloodNeeds: recipientStore.recipientBloodNeeds,
    recipientUserStatus: recipientStore.recipientUserStatus,
    setSelectedTimeSlot: recipientStore.setSelectedTimeSlot,
    registerRecipient: recipientStore.registerRecipient,
    selectedTimeSlot: recipientStore.selectedTimeSlot,
    timeSlotsLength: recipientStore.timeSlotsLength,
    clearRecipient: recipientStore.clearRecipient,
    validTimeSlots: recipientStore.validTimeSlots,
    recipientAsker: recipientStore.recipientAsker,
    setIsModalOpen: recipientStore.setIsModalOpen,
    setRecipientID: recipientStore.setRecipientID,
    setRecipientData: recipientStore.setRecipientData,
    setLoading: recipientStore.setLoading,
    recipientData: recipientStore.recipientData,
    initRecipient: recipientStore.initRecipient,
    isModalOpen: recipientStore.isModalOpen,
    mainLoading: recipientStore.mainLoading,
    isLoading: recipientStore.isLoading,
    isActive: recipientStore.isActive,
    status: recipientStore.status,
    isMobile: uiStore.isMobile,
    recipientStore,
    helpMeStore,
    authStore,
    uiStore,
  }));
};
