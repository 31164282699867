import React from "react";
import styles from "./UserDonorClass.module.scss";
import { User } from "../../../entities";

type Props = {
  user: User;
};

const UserDonorClass: React.FC<Props> = ({ user }) => {
  if (!user) return null;
  const { donor_status } = user;
  return (
    <div className={styles.UserDonorClass}>
      <span
        style={{
          backgroundColor: donor_status?.background_color,
          color: donor_status?.text_color,
        }}
      >
        {donor_status?.name ? donor_status?.name : "Потенциальный донор"}
      </span>
    </div>
  );
};

export default UserDonorClass;
