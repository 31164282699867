import React from "react";
import styles from "./UserBloodType.module.scss";
import { SHORT_BLOOD_GROUPS } from "../../../entities";

type TUserBloodType = {
  user: any;
  style?: React.CSSProperties | null;
};

const UserBloodType: React.FC<TUserBloodType> = ({ user, style = null }) => {
  if (!SHORT_BLOOD_GROUPS[user?.blood_group])
    return (
      <div className={styles.FakeUserBloodType} style={style}>
        <span>
          <br />
        </span>
      </div>
    );
  return (
    <div className={styles.UserBloodType} style={style}>
      <span>{SHORT_BLOOD_GROUPS[user?.blood_group]}</span>
    </div>
  );
};

export default UserBloodType;
